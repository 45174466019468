<template>
  <div class="contacts fade-up">
    <div class="dashboard-table">
      <div class="dashboard-table__header">
        <div
          class="dashboard-table__header-actions"
          :class="{ visible: multipleSelection.length }"
        >
          <div class="selected">{{ multipleSelection.length }} танланган</div>
          <el-button
            type="danger"
            icon="el-icon-delete"
            @click="deleteSelectedContacts()"
          >
            Ўчириш
          </el-button>
        </div>
        <div class="left">
          <el-input
            placeholder="Исм буйича излаш"
            prefix-icon="el-icon-search"
            v-model="search"
          />
        </div>
        <div class="right"></div>
      </div>
      <div class="dashboard-table__body">
        <el-table
          ref="dashboardTable"
          :data="contacts"
          style="width: 100%"
          :default-sort="{ prop: 'created_at', order: 'descending' }"
          @selection-change="handleSelectionChange"
        >
          <el-table-column type="expand">
            <template slot-scope="props">
              <div class="expanded">
                <p><strong>Манзил: </strong> {{ props.row.address }}</p>
                <p><strong>Хабар матни:</strong> {{ props.row.message }}</p>
              </div>
            </template>
          </el-table-column>
          <el-table-column type="selection" width="55" />
          <el-table-column
            prop="created_at"
            label="Мурожат вақти"
            width="160"
            sortable
          >
            <template slot-scope="scope">
              {{ $date(scope.row.created_at).format("HH:mm | DD.MM.YYYY") }}
            </template>
          </el-table-column>
          <el-table-column prop="name" label="Исм"></el-table-column>
          <el-table-column prop="phone" label="Телефон рақами">
            <template slot-scope="scope">
              <a :href="'tel:' + scope.row.phone">{{ scope.row.phone }}</a>
            </template>
          </el-table-column>
          <el-table-column prop="email" label="Электрон почта">
            <template slot-scope="scope">
              <a :href="'mailto:' + scope.row.email">{{ scope.row.email }}</a>
            </template>
          </el-table-column>
          <el-table-column label="Амаллар" width="120">
            <template slot-scope="scope">
              <el-button
                size="mini"
                type="danger"
                icon="el-icon-delete"
                @click.stop="deleteContact(scope.row)"
              />
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="dashboard-table__footer">
        <el-pagination
          :disabled="isLoading"
          background
          layout="prev, pager, next"
          :page-size="perPage"
          :total="totalCount"
          :current-page.sync="currentPage"
        >
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import _ from "lodash";

export default {
  data: () => ({
    isLoading: false,
    search: "",
    perPage: 6,
    currentPage: 1,
    multipleSelection: []
  }),
  computed: {
    contacts() {
      return this.$store.state.contactModule.contacts;
    },
    totalCount() {
      return this.$store.state.contactModule.contactsCount;
    }
  },
  created() {
    this.fetchContacts();
  },
  watch: {
    currentPage() {
      if (this.isLoading) return;

      this.fetchContacts();
    },
    search: _.debounce(function() {
      this.currentPage = 1;
      this.fetchContacts();
    }, 500)
  },
  methods: {
    fetchContacts() {
      if (this.isLoading) return;

      this.isLoading = true;
      const params = {
        _start: this.currentPage * this.perPage - this.perPage,
        _limit: this.perPage,
        "_where[_or][0][name_contains]": this.search
      };
      Promise.all([
        this.$store.dispatch("fetchContacts", params),
        this.$store.dispatch("fetchContactsCount", params)
      ]).finally(() => {
        this.isLoading = false;
      });
    },
    deleteSelectedContacts() {
      this.$confirm("Ўчириш амалини тасдиқлайсизми?", "Диққат!", {
        confirmButtonText: "Ха",
        cancelButtonText: "Бекор қилиш",
        type: "warning"
      })
        .then(() => {
          this.isLoading = true;
          Promise.all(
            this.multipleSelection.map(r => {
              this.$store.dispatch("deleteContact", r.id);
            })
          )
            .then(() => {
              if (this.contacts.length === 0) {
                this.isLoading = false;
                this.fetchContacts();
              }
              this.$message({
                type: "success",
                message: "Ўчирилди"
              });
            })
            .finally(() => (this.isLoading = false));
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "Бекор қилинди"
          });
        });
    },
    deleteContact(row) {
      this.$confirm("Ўчириш амалини тасдиқлайсизми?", "Диққат!", {
        confirmButtonText: "Ха",
        cancelButtonText: "Бекор қилиш",
        type: "warning"
      })
        .then(() => {
          this.isLoading = true;
          this.$store
            .dispatch("deleteContact", row.id)
            .then(() => {
              if (this.contacts.length === 0) {
                this.isLoading = false;
                this.fetchContacts();
              }
              this.$message({
                type: "success",
                message: "Ўчирилди"
              });
            })
            .finally(() => (this.isLoading = false));
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "Бекор қилинди"
          });
        });
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    }
  }
};
</script>

<style lang="scss" scoped>
.contacts {
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 20px;
}
</style>
